<template>
  <div class="opiniones">
    <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="9">
        <h1 class="left">Opiniones</h1>
        <div class="estadistica">
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <b>{{ countStars(5) }}</b>
        </div>
        <div class="estadistica">
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <b>{{ countStars(4) }}</b>
        </div>
        <div class="estadistica">
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <b>{{ countStars(3) }}</b>
        </div>
        <div class="estadistica">
          <i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>
          <b>{{ countStars(2) }}</b>
        </div>
        <div class="estadistica">
          <i class="fa-solid fa-star"></i>
          <b>{{ countStars(1) }}</b>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="3">
        <vs-button @click="getCSV">Descargar CSV</vs-button>
      </vs-col>
    </vs-row>

    <div
      class="box left"
      style="padding:20px;margin-bottom:40px;"
      v-for="feedback in feedbacks"
      :key="feedback.id"
    >
      <span v-for="star in feedback.rating" :key="star.id">
        <i class="fa-solid fa-star"></i>
      </span>
      <span v-for="star in 5 - feedback.rating" :key="star">
        <i class="fa-regular fa-star"></i>
      </span>
      ~
      <b>{{ feedback.name }} {{ feedback.email ? '(' + feedback.email + ') ~ ' : '' }}</b>
      ~ {{ feedback.date }}
      <button class="remove" @click="remove(feedback)">Eliminar</button>
      <hr style="border-top: 1px solid rgb(230, 230, 230)" />
      <br />
      <h3 style="font-weight: 500;" v-html="replaceLinks(feedback.content)"></h3>
    </div>
  </div>
</template>

<style lang="scss">
.estadistica {
  display: inline-block;
  margin-left: 20px;
  background-color: #805cdb;
  color: white;
  padding:5px 10px;
  b {
    margin-left: 10px;
    font-weight: bold;
  }
}
.opiniones  a {
  color: rgb(109, 109, 223);
}
.opiniones a:hover {
  text-decoration: underline;
}
.remove {
  background: darkred;
  color: white;
  float: right;
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin-top: -10px;
  cursor: pointer;
}
.remove:hover {
  background: red;
}
.remove:active {
  background: black;
}
</style>
<script>
/* eslint-disable */
import { db, query, addDoc, doc, collection, deleteDoc, getDoc, getDocs, onSnapshot, orderBy, httpsCallable, functions, where } from '../firebase'
import TableRender from './Rage/TableRender.vue';
import moment from 'moment';
export default {
  name: 'Admin',
  components: { TableRender },
  data() {
    return {
      feedbacks: []
    }
  },
  async mounted() {
    this.company = (await getDoc(doc(db, "clients", this.$store.state.user.currentTenant))).data();
    this.companyId = (await getDoc(doc(db, "clients", this.$store.state.user.currentTenant))).id;
    this.link = `${window.location.origin}/${this.company.uri}`;

    const q = query(collection(db, "clients", this.companyId, 'feedbacks'), orderBy('created', 'desc'));
    onSnapshot(q, snapshot => {
      this.feedbacks = []
      this.ready = false;
      snapshot.forEach(doc => {
        this.feedbacks.push({
          id: doc.id,
          date: moment(doc.data().created.toDate()).format('DD/MM/YYYY, HH:mm'),
          ...doc.data(),
        });
      })
      this.$nextTick(_ => this.ready = true)
    })
  },
  methods: {
    countStars(amount) {
      return (this.feedbacks.filter(feedback => feedback.rating === amount)).length
    },
    replaceLinks(text) {
      var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
      return text.replace(exp, "<a href='$1'>$1</a>");
    },
    getCSV() {
      console.log(`bajos`, this.feedbacks);
      let csv = this.feedbacks.map(feedback =>
        `"${feedback.name}","${feedback.email || ''}","${feedback.content.replace(/"/g, '')}","${feedback.rating}",`
        + moment(feedback.created.toDate()).format('DD/MM/YYYY - HH:mm')).join('\n');
      var encodedUri = encodeURI('data:text/csv;charset=utf-8,Nombre,Email,Feedback,Valoracion,Fecha\n' + csv);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Opiniones.csv");
      document.body.appendChild(link); // Required for FF
      link.click();

    },
    remove(feedback) {
      if (!confirm('¿Eliminar la opinión de ' + feedback.name + '?')) return;
      deleteDoc(doc(db, "clients", this.companyId, 'feedbacks', feedback.id));
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.opiniones .generic-code {
  width: 100%;
  border: 2px dashed rgb(185, 185, 185);
  padding: 12px;
  border-radius: 5px;
  background: rgb(230, 229, 229);
  .vs-input--input {
    background: white;
  }
}
.opiniones .vs-input--icon {
  cursor: pointer;
  &:hover {
    color: rgba(var(--vs-primary), 1) !important;
  }
}
</style>